.d_shops table th{
    padding: 8px 10px;
}
.d_shops td{
    padding: 10px 10px;
    text-align: center;
}
.d_shops tbody tr:hover{
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    
}

.d_shops input{
    width: 100%;
    display: block;
    margin: 5px 0px;
    border: 1px solid gray;
    outline: none;
    padding: 4px 8px;
    border-radius: 4;
}
.d_shops select{
    width: 100%;
    display: block;
    margin: 5px 0px;
    border: 1px solid gray;
    outline: none;
    padding: 4px 8px;
    border-radius: 4;
}
.d_shops label{
    font-size: 16px;
    font-weight: 600;

}