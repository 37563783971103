.Shop_page{
    /* display: grid;
    grid-template-columns: 1fr .4fr;
    gap: 20px; */
}
.shop_page_rightbar{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-template-rows: repeat(7,150px);
  gap: 20px;
}


@media(max-width : 550px){
  .shop_hero{
    flex-direction: column !important;
  }
  .shop_offer_list{
    flex-direction: column;
  }
  .shop_offer_list_image_box{
    width: 100%;
    height: 150px ;
  }
  .image_box_content_div{
    width: 100%;
  }

}