



@media(max-width : 550px){
    .swiper-slide{
      height: auto !important;
    }
    .hero_main_div{
        height: auto !important;
    }
    .swiper-slide img{
        object-fit: contain !important;
    }
}