.bashboard_sidebar ul li{
    border-bottom: 1px solid rgba(0, 0,0,.1);
    padding: 6px 10px;
    margin: 10px 0px;
    cursor: pointer;
    border-radius: 8px;
    background-color: white;
}
.bashboard_sidebar{
    box-shadow: 0px 0px 20px 0px rgba(0, 0,0,.3);

}