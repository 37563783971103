.left_navbar{
    align-items: center !important;
}
.left_navbar ul  li{
    font-size: 17px;
    padding: 3px 6px;
    margin: 0px 5px;
    cursor: pointer;
}

.right_navbar{
    background-color: #FAF7FD;
}
.right_navbar input{
    background-color: transparent;
    padding: 2px 0px;
    outline: none;
    width: 90%;
    padding: 8px 20px 8px 20px;
}
.right_navbar svg{
}

.cross{
    display: none;
}
.Mobile_Navbar{
    display: none;
}

.react_ui_circle_navbar_div {
    z-index: 10000000000000;
}

/* -- media  */
@media(max-width : 1200px){
    .left_navbar ul{
        width: 100% !important;
    }
    .left_navbar ul li{
        font-size: 15px;
    }
    .navbar_parent h1{
        width: 20%;
    }
}

@media(max-width : 991px){
    .Mobile_Navbar{
        display: block;
        z-index: 1000000000000;
        background-color: white;
    }
    .Navbar{
        flex-direction: column !important;
    }
    .Navbar ul{
        flex-direction: column;
    }
    .left_navbar{
        flex-direction: column;
        gap: 20px;
    }
    .Navbar{
        justify-content: flex-start !important;
        align-items: flex-start !important;
        width: 320px !important;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000000000000;
        background-color: white;
        padding: 20px 10px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5);

    }
    .Navbar h1{
        width: 100% !important;
    }
    .left_navbar ul{
        width: 100% !important;
    }
    .Navbar ul li{
        text-align: left;
        width: 100%;
    }
    .right_p{
        width: 100%;
    }
    .right_navbar{
        width: 100% !important;
    }
    .cross{
        display: block;
        margin-bottom: -10px;
    }
    .heading_p{
        width: 100%;
        display: flex;   
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }
}

@media(max-width : 768px){
    .navbar_parent{
        padding: 12px 10px !important;
    }
}
@media(max-width: 500px){
    .Navbar{
        width: 100% !important;
    }
}