.home_grid{
    display: grid;
    grid-template-columns: 1fr .3fr;

}
.sidebar_shops{
    min-width: 300px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 10px;
    max-height: 600px;
}



@media(max-width : 1050px){
    .home_grid{
        grid-template-columns: 1fr;
    }
}

@media(max-width : 500px){
    .sidebar_shops{
       min-width: 100%;
    }
}