


@media(max-width : 640px){
    .modal_div{
        width: 80%;
    }
}

@media(max-width : 540px){
    .modal_div{
        width: 90%;
    }
}